import React, { Component } from "react";
import styled from 'styled-components';
import inverimg from './Inver_02.gif';

const GridDiv1 = styled.div
`
   display: grid;
   height: 100vh;
   width: 100vw;
   {/*
   border-style: solid;
   border-width: 2px;
   border-color: orange;
   */}
   background-color: lightblue;
   grid-template-areas:
   "ha hb hc "
   ".  ma ."
   "bg bg bg"
   ".  l4 . "
   "bm bm bm";
   grid-template-columns:  minmax(2em, auto)
                           minmax(450px, 700px)
                           minmax(2em, auto);
   grid-template-rows:  minmax(1rem, 100px)
                        minmax(350px, 426px)
                        minmax(0.5rem, auto)
                        80px
                        minmax(1rem, auto);
`;

const GridNest1 = styled.div
`
   grid-area: ma;
   border-style: groove;
   border-width: 5px;
   border-color: lightgray;
   background-color: white;
   {/* vvvvv Nested Grid vvvvvvvvvvvvvvvvvvv */}
   display: grid;
   grid-template-areas:
   "lm m0 rm"
   "lm m1 rm"
   "lm m2 rm"
   "lm m3 rm"
   "lm m4 rm"
   "lm m5 rm";
   "lm m6 rm";
   grid-template-columns:  minmax(1em, auto)
                           minmax(350px, auto)
                           minmax(1em, auto);
   grid-template-rows:  minmax(1rem, auto)
                        minmax(2.0rem, auto)
                        minmax(1rem, auto)
                        2.5rem
                        minmax(1.0rem, auto)
                        minmax(2.0rem, auto)
                        minmax(1rem, auto);
`;

const GN1R1 = styled.div                       /* www.domain.name */
`
   grid-area: m1;

   display: grid;
   align-items: center;
   justify-items: center;

   padding-top: 3%;
   
   {/*
   border-style: solid;
   border-width: 1px;
   border-color: lightgray;
   */}


   
   background-color: white;
   {/*      */}
   font-family: "Courier New", Courier, mono;
   font-size: 24px;
   font-weight: bold;
   color: #006699;

`;
const GN1R2 = styled.div                       /* Hosted in the UK by */
`
   grid-area: m2;

   display: grid;
   align-items: center;
   justify-items: center;

   padding-top: 2%;
   
   {/*
   border-style: solid;
   border-width: 1px;
   border-color: lightgray;
   */}
   
   background-color: white;
   {/*      
   font-family: "Courier New", Courier, mono;
   font-size: 24px;
   font-weight: bold;
   color: #006699; */}
   {/*
   text-align: center;
   margin-top: auto;
   margin-bottom: auto;
   */}

`;
const GN1R3 = styled.div                       /* Inver Systems */
`
   grid-area: m3;

   display: grid;
   align-items: center;
   justify-items: center;

   {/*
   border-style: solid;
   border-width: 1px;
   border-color: lightgray;
   */}
   
   {/* background-color: white; */}
   {/* 
   background-image: url(${inverimg});
   */}
   background-repeat: no-repeat;
   background-position: center center;
   background-size: contain;
   overflow: hidden;
   cursor: pointer;
   {/*
   text-align: center;
   margin-top: auto;
   margin-bottom: auto;
   */}
`;
const GN1R4 = styled.div
 `
   grid-area: m4;
   
   display: grid;
   align-items: center;
   justify-items: center;

   padding-top: 3%;
   
   {/*
   border-style: solid;
   border-width: 1px;
   border-color: lightgray;
   */}
   {/* */}
   background-color: white;
      {/*      */}
   font-family: Arial, Helvetica, sans-serif;
   font-size: 12px;
   color: #666666;
   {/*
   text-align: center;
   margin-top: auto;
   margin-bottom: auto;
   */}
`;
const GN1R5 = styled.div
`
   grid-area: m5;

   display: grid;
   align-items: center;
   justify-items: center;

   {/*
   border-style: solid;
   border-width: 1px;
   border-color: lightgray;
   */}
   
   background-color: white;
      {/*      */}
   font-family: Arial, Helvetica, sans-serif;
   font-size: 14px;
   color: #000000;
   {/*
   text-align: center;
   margin-top: auto;
   margin-bottom: auto;
   */}
`;

class App extends Component
{
   render()
   {
      return (
         <GridDiv1>
            <GridNest1>
               <GN1R1>
                  <p>www.lovejoy.me.uk</p>
               </GN1R1>
               <GN1R2>
                  <p>Hosted in the UK by:</p>
               </GN1R2>
               <GN1R3>
                  <a href="http://www.inversystems.co.uk">
                     <img src={inverimg}  alt='Inver Systems'/>
                  </a>
               </GN1R3>
               <GN1R4>
                  <p>For:</p>
               </GN1R4>
               <GN1R5>
                  <p>Lovejoys</p>
               </GN1R5>
            </GridNest1>
         </GridDiv1>

      );
   }
}

export default App;

